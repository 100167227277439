exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-best-bitcoin-guides-js": () => import("./../../../src/pages/resources/best-bitcoin-guides.js" /* webpackChunkName: "component---src-pages-resources-best-bitcoin-guides-js" */),
  "component---src-pages-resources-bitcoin-js": () => import("./../../../src/pages/resources/bitcoin.js" /* webpackChunkName: "component---src-pages-resources-bitcoin-js" */),
  "component---src-pages-resources-bitcoin-tools-js": () => import("./../../../src/pages/resources/bitcoin-tools.js" /* webpackChunkName: "component---src-pages-resources-bitcoin-tools-js" */),
  "component---src-pages-resources-books-js": () => import("./../../../src/pages/resources/books.js" /* webpackChunkName: "component---src-pages-resources-books-js" */),
  "component---src-pages-resources-coin-control-js": () => import("./../../../src/pages/resources/coin-control.js" /* webpackChunkName: "component---src-pages-resources-coin-control-js" */),
  "component---src-pages-resources-hardware-wallets-js": () => import("./../../../src/pages/resources/hardware-wallets.js" /* webpackChunkName: "component---src-pages-resources-hardware-wallets-js" */),
  "component---src-pages-resources-lightning-js": () => import("./../../../src/pages/resources/lightning.js" /* webpackChunkName: "component---src-pages-resources-lightning-js" */),
  "component---src-pages-resources-mobile-wallets-and-payment-apps-js": () => import("./../../../src/pages/resources/mobile-wallets-and-payment-apps.js" /* webpackChunkName: "component---src-pages-resources-mobile-wallets-and-payment-apps-js" */),
  "component---src-pages-resources-multisig-js": () => import("./../../../src/pages/resources/multisig.js" /* webpackChunkName: "component---src-pages-resources-multisig-js" */),
  "component---src-pages-resources-security-privacy-js": () => import("./../../../src/pages/resources/security-privacy.js" /* webpackChunkName: "component---src-pages-resources-security-privacy-js" */),
  "component---src-pages-resources-software-wallets-js": () => import("./../../../src/pages/resources/software-wallets.js" /* webpackChunkName: "component---src-pages-resources-software-wallets-js" */),
  "component---src-pages-resources-tools-js": () => import("./../../../src/pages/resources/tools.js" /* webpackChunkName: "component---src-pages-resources-tools-js" */),
  "component---src-pages-tools-satoshis-converter-js": () => import("./../../../src/pages/tools/satoshis-converter.js" /* webpackChunkName: "component---src-pages-tools-satoshis-converter-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

